.nav-product li {
  border-radius: 0!important;
  border: 1px solid #E60012;
}

.nav-product Button {
  color: #E60012;
  border-radius: 0!important;
  border: 0;
}
.nav-product li {
  border-left-width: 0;
}

.nav-product li:first-child {
  border-top-left-radius: .25rem!important;
  border-bottom-left-radius: .25rem!important;
  border-left-width: 1px;
}
.nav-product li:last-child {
  border-top-right-radius: .25rem!important;
  border-bottom-right-radius: .25rem!important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #E60012!important;
}

dl.striped {
  margin-left: 0;
  margin-right: 0;
}

dl.striped dd,
dl.striped dt
{
  margin-bottom: 0;
  padding: .5em 1em;
  word-break: break-word;
}
dl.striped dt:nth-child(4n+1),
dl.striped dd:nth-child(4n+2)
{
  background-color: #F4F4F4;
;
}

.button-container {
  text-align: center;
  margin-bottom: 10px;

  .purchase-button {
    background-color: #e60012;
  }
}
