@import url(https://fonts.googleapis.com/css2?family=Lato);
@import url(https://fonts.googleapis.com/css2?family=Roboto);

.lato {
  font-family: Lato, Arial, sans-serif;
}

.roboto {
  font-family: Roboto, Arial, sans-serif;
}

// Font sizes
.normal-font {
  font-size: 16px;
}

.medium-font {
  font-size: 14px;
}

.small-font {
  font-size: 12px;
}