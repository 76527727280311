.overlay-modal, .overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
}

.overlay {
  background: rgba(49,49,49,0.8);
}

.overlay-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  min-width: 300px;
}
