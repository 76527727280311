.breadcrumbs {
  list-style: none;
  background: inherit;
  margin: 0 0 0;
  padding: 0;
  font-size: 14px;
}

.breadcrumbs li {
  display: inline-block;
}

.breadcrumbs li a {
  color: #727272;
}

.breadcrumbs li::after {
  padding-left: .5em;
  font-family: "Font Awesome 5 Pro", serif;
  font-weight: 900;
  font-size: 10px;
  content: "\f054";
  color: #727272;
  margin-right: .5em;
}