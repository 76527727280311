@use '../app_settings';

.category-selector {
  display: flex;
  flex-direction: column;
  font-size: 23px;
  padding-top: 16px;
  background-color: #f6f6f6;

  .top-level-category {
    padding-top: 8px;
  }

  .category-selector-title-and-breadcrumb {
    margin-left: 16px;
    margin-right: 16px;
  }

  .category-selector-title {
    font-size: 24px;
    font-weight: 700;
  }
}