.dimmer {
    background: #000;
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.loading-spinner {
  display: flex;
  height: 50vh;
  justify-content: center;
  align-items: center;
}