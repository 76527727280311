.retry-button {
    min-width: 250px;
    border-radius: 100px;
    border: 1px solid transparent;
    background-color: #b1b1b1b1;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 12px;
    color: black;
    line-height: 2;
}