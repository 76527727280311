@use '../app_settings';

.bottom-nav-bar {
  background-color: #fafafa;
  border-top: 2px solid #eaeaea;
  font-size: 22px;

  @include app_settings.media-breakpoint-up(sm) {
    & {
      font-size: 19px;
    }
  }
}

.exit-kiosk-button {
  background-color: #fafafa!important;
}