@use '../common';
@use '../app_settings';

.header {
  display: flex;
  justify-content: center;
  background-color: #e60012;
  width: 100%;

  .top-bar-container {
    display: flex;
    justify-content: center;
    width: 100%;

    .top-bar {
      display: flex;
      width: 100%;

      .banner {
        display: flex;
        height: 46px;
        align-self: center;
        padding: 8px 0 8px 15px;
      }
    }
  }
}
