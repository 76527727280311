@use '../app_settings';

.login-buttons {
  display: flex;
  flex-direction: column;
  padding-top: 5px;

  .btn-login {
    margin-top: 50px;
    text-align: center;
    background: #E50210;
    color: #FFF;
    border-color: transparent;
  }

  .btn-guest {
    text-align: center;
    background: transparent;
    color: #E50210;
    border-color: #E50210;
  }

  .or-text {
    font-size: 18px;
  }
}