@use '../app_settings';

.category-link{
  text-decoration: none;
}

.product-tile {
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
  height: 100%;

  .product-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 10px;
    font-size: 60px;

    .product-image {
      display: flex;
      max-width: 100%;
      padding-top: 15px;
      margin-bottom: 15px;
      max-height: 124px;

      @include app_settings.media-breakpoint-down(sm) {
        & {
          font-size: 45px;
        }
      }
    }
  }

  .product-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 15px;

    @include app_settings.media-breakpoint-down(sm) {
      & {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .product-tile-title {
      font-weight: 700;
      font-size: 18px;
      color: black;

      @include app_settings.media-breakpoint-down(sm) {
        & {
          font-size: 18px;
        }
      }
    }
  }
}