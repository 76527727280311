@mixin for-small-screen {
  @media (max-width: 599px) { @content; }
}

.quiz-container {
  background-color: #f6f6f6;
}

.quiz-title {
  font-size: x-large;
  font-weight: bold !important;
}

.quiz-tile {
  background-color: white;
  font-family: Roboto Condensed, serif;
  font-weight: bold !important;
}

.selected-answer-correct {
  border-color: #1BD676 !important;
  border-width: 13px !important;
  border-style: solid;
}

.selected-answer-incorrect {
  border-color: #EB0900 !important;
  border-width: 13px !important;
  border-style: solid;
  background-color: #CCCCCC !important;
}

.answer-image {
  padding: 5% 5% 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.answer-image-overlay {
  position: absolute;
  background-color: #414042;
  color: white;
  border-radius: 70px;
  padding: 5px 20px;

  @include for-small-screen {
    border-radius: 70px;
    padding: 2px 10px;
  }
}

.answer-text {
  padding-top: 3%;
  margin-bottom: 5%;
  text-align: center;
  color: #414042;
}

.question-text {
  padding-top: 2%;
  margin-bottom: 2%;
  color: #414042;
  font-size: large;
}

.quiz-button {
  background-color: #5361BD !important;
  border-radius: 0 !important;
  font-weight: bold !important;
  margin-top: 1%;
}

.results-image-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.results-image-overlay {
  position: absolute;
  bottom: 0;
  color: #414042;
  font-size: xx-large;
  margin-bottom: 40px;

  @include for-small-screen {
    font-size: large;
    margin-bottom: 8px;
  }
}

.question-overlay {
  position: absolute;
  z-index: 1;
  text-align: left;
  color: white;
}

.question-count-overlay {
  background-color: #414042;
  padding: 10px 45px;
  max-width: 200px;

  @include for-small-screen {
    padding: 2px 10px;
  }

}

.answered-question-overlay {
  margin-left: 25px;
  margin-right: 10px;
  margin-bottom: 10px;
  bottom: 0;
}

.answered-gradient {
  position:relative;
}

.answered-gradient::after {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 100%; height: 100%;
  background: transparent linear-gradient(180deg, #414042 0%, #3F3E4000 0%, #0D0D0D 100%) 0 0 no-repeat padding-box;
}

.answered-question-header {
  font-size: x-large;
}

.answered-question-text {
  font-size: medium;
}

.retry-text {
  text-align: center;
  font-family: Roboto Condensed, serif;
  font-weight: bold !important;
  font-size: large;
}