@use '../app_settings';

.product-selector {
  display: flex;
  flex-direction: column;
}

.product-selector-tiles {
  background-color: rgba(132, 198, 185, 0.14);
}

.category-name {
  font-weight: 700;
  font-size: 24px;
}

.btn:disabled {
  cursor: default;
  background-color: #DADADA;
  border: #DADADA;
  color: #000000;
}

.pagination-container {
  max-height: 40px;
}

.pagination-text {
  width: 115px;
}