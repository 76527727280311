@use '../app_settings';

.category-link{
  text-decoration: none;
}

.category-tile {
  align-items: center;
  height: 124px;

  .category-image-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    width: 110px;
    font-size: 60px;

    .category-image {
      width: 100%;

      @include app_settings.media-breakpoint-down(sm) {
        & {
          font-size: 45px;
        }
      }
    }
  }

  .category-info-container {
    height: 84px;
    padding-left: 15px;
    padding-right: 15px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @include app_settings.media-breakpoint-down(sm) {
      & {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .category-tile-title {
      font-weight: 700;
      font-size: 24px;

      @include app_settings.media-breakpoint-down(sm) {
        & {
          font-size: 18px;
        }
      }
    }

    .category-tile-description {
      font-size: 16px;

      @include app_settings.media-breakpoint-down(sm) {
        & {
          font-size: 14px;
        }
      }
    }
  }
}

.activity-modal-close {
  float: right !important;
  background-color: transparent;
  border: none;
}

.activity-modal-text {
  font-family: museo-sans, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}